var render = function render(){var _vm=this,_c=_vm._self._c;return (false)?_c('div',{attrs:{"id":"carnet-content"}},[_c('v-btn',{staticClass:"mx-6 my-4",attrs:{"type":"button","color":"indigo darken-4 ml-4 white--text","dark":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{staticClass:"mx-4",attrs:{"dark":""}},[_vm._v("arrow_back")])],1),(_vm.download)?_c('v-btn',{staticStyle:{"float":"right"},attrs:{"type":"button","color":"primary"},on:{"click":_vm.generatePDF}},[_vm._v("Generar PDF")]):_vm._e(),_c('div',{attrs:{"id":"carnet"}},[_c('div',{staticClass:"carnet-izq",style:({
        'background-image':
          'url(' + require('@/assets/media/rpg/carnet/bg-ladoizq.png') + ')',
      })},[_c('table',{staticClass:"datos-carnet"},[_c('tr',[_c('th',[_vm._v("Fishing Gears")]),_c('td',[_vm._v(_vm._s(_vm.fishing_arts))])]),_c('tr',[_c('th',[_vm._v("Expedition Place")]),_c('td',[_vm._v(_vm._s(_vm.expedition_place))])]),_c('tr',[_c('th',[_vm._v("Landing Site")]),_c('td',[_vm._v(_vm._s(_vm.landing_zone))])]),_c('tr',[_c('th',[_vm._v("Nationality")]),_c('td',[_vm._v(_vm._s(_vm.nationality))])]),_c('tr',[_c('th',[_vm._v("Municipality")]),_c('td',[_vm._v(_vm._s(_vm.municipio))])])]),_c('div',{staticClass:"nota-legal-carnet"},[_vm._v(" We Request the Civilian Authorities to provide Cooperation and support required by the carrier during the development of the activity. ")]),_c('div',{staticClass:"datos-expedicion-carnet"},[_c('div',{staticClass:"firma-carnet"},[_c('v-img',{staticClass:"firma-carnet-img",attrs:{"src":_vm.firmaUrl +
              this.$store.state.settings.find(
                (x) => x.nombre == 'firma_secretario'
              ).valor,"width":"109","alt":"firma"}}),_c('p',[_vm._v(" "+_vm._s(this.$store.state.settings.find( (x) => x.nombre == "nombre_secretario" ).valor)+" ")])],1)])]),_c('div',{staticClass:"carnet-der",style:({
        'background-image':
          'url(' + require('@/assets/media/rpg/carnet/bg-ladoder.png') + ')',
      })},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"align":"end","justify":"end"}},[_c('v-img',{attrs:{"src":require('@/assets/media/rpg/carnet/logo-gobernacion-sai.png'),"width":"207","height":"43","max-width":"207","max-height":"43","alt":"RGP"}})],1)],1),_c('div',{staticClass:"datos-pescador-carnet"},[_c('div',{staticClass:"foto-carnet"},[_c('v-img',{attrs:{"src":_vm.path + _vm.fisherman_photo_file}})],1),_c('div',{staticClass:"datos-personales-carnet"},[_c('div',{staticClass:"nombre-pescador-carnet"},[_c('span',{staticClass:"nombres-pc"},[_vm._v(_vm._s(_vm.name))])]),_c('div',{staticClass:"nombre-pescador-carnet"},[_c('span',{staticClass:"nombres-pc"},[_vm._v(_vm._s(_vm.lastName))])]),_c('table',{staticClass:"num-ids-carnet"},[_vm._m(0),_c('tr',[_c('td',[_vm._v(_vm._s(this.identification_number))]),_c('td',[_vm._v(_vm._s(this.fishin_card_number))])]),_c('tr',[_c('td',{staticClass:"carnet-dates"},[_vm._v("Issued: "+_vm._s(this.expedition_date))]),_c('td')]),_c('tr',[_c('td',{staticClass:"carnet-dates"},[_vm._v("Expire: "+_vm._s(this.expiration_date))]),_c('td')])])]),_c('div',{staticClass:"clear"}),_c('v-container',{staticClass:"pr-1 mt-2",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"end","justify":"end"}},[_c('v-img',{staticStyle:{"left":"0","bottom":"20px"},attrs:{"src":_vm.type_activity_img,"alt":"RGP","max-width":"180"}})],1)],1)],1)],1)])],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Nº. Document")]),_c('th',[_vm._v("Nº. License")])])
}]

export { render, staticRenderFns }