<template>
  <div id="carnet-content" v-if="false">
    <v-btn
      type="button"
      @click="$router.go(-1)"
      class="mx-6 my-4"
      color="indigo darken-4 ml-4 white--text"
      dark
    >
      <v-icon dark class="mx-4">arrow_back</v-icon>
    </v-btn>
    <v-btn
      type="button"
      color="primary"
      v-if="download"
      style="float: right"
      @click="generatePDF"
      >Generar PDF</v-btn
    >
    <div id="carnet">
      <div
        class="carnet-izq"
        :style="{
          'background-image':
            'url(' + require('@/assets/media/rpg/carnet/bg-ladoizq.png') + ')',
        }"
      >
        <table class="datos-carnet">
          <tr>
            <th>Fishing Gears</th>
            <td>{{ fishing_arts }}</td>
          </tr>
          <tr>
            <th>Expedition Place</th>
            <td>{{ expedition_place }}</td>
          </tr>
          <tr>
            <th>Landing Site</th>
            <td>{{ landing_zone }}</td>
          </tr>
          <tr>
            <th>Nationality</th>
            <td>{{ nationality }}</td>
          </tr>
          <tr>
            <th>Municipality</th>
            <td>{{ municipio }}</td>
          </tr>
        </table>

        <div class="nota-legal-carnet">
          We Request the Civilian Authorities to provide Cooperation and support
          required by the carrier during the development of the activity.
        </div>

        <div class="datos-expedicion-carnet">
          <div class="firma-carnet">
            <v-img
              class="firma-carnet-img"
              :src="
                firmaUrl +
                this.$store.state.settings.find(
                  (x) => x.nombre == 'firma_secretario'
                ).valor
              "
              width="109"
              alt="firma"
            ></v-img>
            <p>
              {{
                this.$store.state.settings.find(
                  (x) => x.nombre == "nombre_secretario"
                ).valor
              }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="carnet-der"
        :style="{
          'background-image':
            'url(' + require('@/assets/media/rpg/carnet/bg-ladoder.png') + ')',
        }"
      >
        <v-container class="pa-0">
          <v-row align="end" justify="end">
            <!--v-col cols="12" class="pa-0" justify="end" align="end"-->
            <v-img
              :src="
                require('@/assets/media/rpg/carnet/logo-gobernacion-sai.png')
              "
              width="207"
              height="43"
              max-width="207"
              max-height="43"
              alt="RGP"
            ></v-img>
            <!--/v-col-->
          </v-row>
        </v-container>
        <div class="datos-pescador-carnet">
          <div class="foto-carnet">
            <v-img :src="path + fisherman_photo_file"></v-img>
          </div>
          <div class="datos-personales-carnet">
            <div class="nombre-pescador-carnet">
              <span class="nombres-pc">{{ name }}</span>
            </div>
            <div class="nombre-pescador-carnet">
              <span class="nombres-pc">{{ lastName }}</span>
            </div>

            <table class="num-ids-carnet">
              <tr>
                <th>Nº. Document</th>
                <th>Nº. License</th>
              </tr>
              <tr>
                <td>{{ this.identification_number }}</td>
                <td>{{ this.fishin_card_number }}</td>
              </tr>
              <tr>
                <td class="carnet-dates">Issued: {{ this.expedition_date }}</td>
                <td></td>
              </tr>
              <tr>
                <td class="carnet-dates">Expire: {{ this.expiration_date }}</td>
                <td></td>
              </tr>
            </table>
          </div>
          <div class="clear"></div>

          <v-container fluid class="pr-1 mt-2">
            <v-row align="end" justify="end">
              <v-img
                :src="type_activity_img"
                alt="RGP"
                max-width="180"
                style="left: 0; bottom: 20px"
              ></v-img>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { photoFishermanUrl, firmaUrl } from "./../config.js";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { mapState } from "vuex";
import {
  imgLogoGobernacion,
  imgDataUp,
  imgData,
} from "../assets/base64/imges-carnet";

import baseService from "../services/BaseService.js";
const DEFAULT_VALUE = "Desconocido";
const SPORT_CODE = "DOS";
export default {
  data() {
    return {
      firmaUrl: firmaUrl,
      id: 0,
      path: photoFishermanUrl,
      page_back: "",
      fisherman_photo_file: "",
      imagePreview: "",
      filed: "",
      name: "",
      lastName: "",
      identification_number: "",
      fishin_card_number: "",
      type_of_card: "",
      expiration_date: "",
      fishing_arts: "",
      nationality: "",
      landing_zone: "",
      expedition_place: "",
      activity: "",
      type_activity_img: "",
      municipio: "",
      imgFirma: "",
      download: false,
      fishermanStatus: "",
      fishermanOrganization: "",
      fishermanExpeditionPLace: "",
      fishermenNationality: "",
      fishingCard: {},
      timeInActivity: null,
      isSport: false,
    };
  },
  computed: {
    ...mapState(["fisherman"]),
    fishermanOrg() {
      if (this.fishermanStatus === "INDEPENDENT") {
        return "NOT APPLICABLE";
      } else {
        return this.fishermanOrganization;
      }
    },
    occreType() {
      if (this.fishingCard.occre === "RESIDENTE") {
        return "RESIDENT";
      } else if (this.fishingCard.occre === "RAIZAL") {
        return "RAIZAL";
      } else {
        return "";
      }
    },
  },
  created(fisherman = []) {
    const { fishingCard } = fisherman.length == 0 ? this.fisherman : fishingCard;
    this.fishingCard = fishingCard;
    this.page_back = fishingCard.page_back;
    this.fisherman_photo_file = fishingCard.fisherman_photo_file;
    this.identification_number = fishingCard.identification_number;
    this.name = fishingCard.name;
    this.lastName = fishingCard.lastname;
    this.fishin_card_number = fishingCard.fishin_card_number;
    this.expiration_date = fishingCard.expiration_date
      ? moment(fishingCard.expiration_date).format("YYYY-MM-DD")
      : "";
    this.nationality = fishingCard.nationality;
    this.activity = fishingCard.activity;
    this.landing_zone =
      fishingCard.landing_zone_state ||
      fishingCard.puerto_desembarque?.nombre ||
      DEFAULT_VALUE;
    this.expedition_place = fishingCard.expedition_place;
    this.expedition_date = fishingCard.expedition_date;
    this.fishermanStatus =
      fishingCard.organization_cooperative === "SI"
        ? "ASSOCIATED"
        : "INDEPENDENT";
    this.fishermanOrganization = fishingCard.organization?.abbr_name || "";
    this.fishermanExpeditionPLace = fishingCard.lugar?.nombre || "";
    this.fishing_arts = fishingCard.fishing_arts;
    this.fishermenNationality =
      fishingCard.nacionalidad?.nombre || DEFAULT_VALUE;
    this.timeInActivity =
      this.fishingCard.tiempo_en_actividad?.nombre_ingles ||
      this.fishingCard.time_in_activity;
    this.isSport = fishingCard.tipo_permiso?.codigo === SPORT_CODE;
    switch (fishingCard.tipo_permiso?.codigo) {
      case "UNO":
        this.type_of_card = "Pesca Comercial Artesanal";
        this.activity = "Artisanal Fishing";
        this.type_activity_img = require("@/assets/media/rpg/carnet/artisanal-card.png");
        break;
      case "DOS":
        this.type_of_card = "Pesca Sportiva";
        this.activity = "Sport Fishing";
        this.type_activity_img = require("@/assets/media/rpg/carnet/sport-card.png");
        break;
      case "TRES":
        this.type_of_card = "Otro";
        this.activity = "Other";
        this.type_activity_img = require("@/assets/media/rpg/carnet/logo-rgp-carnet.png");
        break;
    }
    switch (fishingCard.location) {
      case "UNO":
        this.expedition_place = "SAN ANDRES";
        break;
      case "DOS":
        this.expedition_place = "PROVIDENCIA";
        break;
    }
    switch (fishingCard.location) {
      case "UNO":
        this.municipio = "SAN ANDRES";
        break;
      case "DOS":
        this.municipio = "PROVIDENCIA";
        break;
    }
    var urlFoto = this.path + this.fisherman_photo_file;
    this.generarBase64(urlFoto);

    let imgFirma = "";

    const url =
      firmaUrl +
      this.$store.state.settings.find((x) => x.nombre == "firma_secretario")
        .valor;

    const prome = new Promise((resolve) => {
      this.generarBase64(url).then((data) => {
        imgFirma = data;
        resolve();
      });
    });

    prome.then(() => {
      this.imgFirma = imgFirma;
      this.download = true;
      this.generatePDF();
    });
  },
  methods: {
    async generatePDF() {
      let doc = await this.generarBlobPdf();
      window.open(doc.output("bloburl"), "_blank");
      this.$store.commit("SET_FISHING_CARD", null);
    },
    async generarBlobPdf()
    {
      const doc = new jsPDF("l", "mm", [800, 470], "legal");
      var urlFoto = this.path + this.fisherman_photo_file;
      const imgLogoFishingCard = this.type_activity_img;
      doc.addImage(imgDataUp, "PNG", 0, 0, 300, 166);
      doc.addImage(this.imgFirma, "PNG", 100, 115, 95, 35);
      doc.setProperties({
        title: `${this.name} ${this.lastName}  - ${this.fishin_card_number}`,
      });
      const imgFotoPescador = await this.generarBase64(urlFoto);
      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setFontSize(30)
        .setTextColor("#1A237E")
        .text("Fishing Gears".toUpperCase(), 13, 20);

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(
          110,
          20,
          ` ${
            this.fishing_arts.map(
              (x) => "" + (x?.pivot?.description || x?.nombre || x)
            ) || DEFAULT_VALUE
          } `
            .trim()
            .toUpperCase()
        );

      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setTextColor("#1A237E")
        .setFontSize(30)
        .text(13, 32, "Landing Site".toUpperCase());
      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(110, 32, `${this.landing_zone}`.toUpperCase());
      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setTextColor("#1A237E")
        .setFontSize(30)
        .text(13, 45, `STATUS`.toUpperCase());

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(110, 45, `${this.fishermanStatus}`.toUpperCase());

      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setTextColor("#1A237E")
        .setFontSize(30)
        .text(13, 58, `ORGANIZATION`.toUpperCase());

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(110, 58, `${this.fishermanOrg}`.toUpperCase());

      !this.isSport && doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setTextColor("#1A237E")
        .setFontSize(30)
        .text(13, 71, `DEDICATION`.toUpperCase());

      !this.isSport && doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(110, 71, `${this.timeInActivity}`.toUpperCase());
      const heightRowOccre = !this.isSport ? 84 : 71;
      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setTextColor("#1A237E")
        .setFontSize(30)
        .text(13, heightRowOccre, `OCCRE TYPE`.toUpperCase());

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(110, heightRowOccre, `${this.occreType}`.toUpperCase());
      doc.setDrawColor(0);
      doc.setFillColor("#eee");
      doc.roundedRect(0, 90, 300, 25, 3, 3, "F");

      doc
        .setFont("Helvetica")
        .setTextColor("#333")
        .setFontStyle("normal")
        .setFontSize(22)
        .text(
          140,
          100,
          `We Request the Civilian Authorities to provide Cooperation and`,
          null,
          null,
          "center"
        );

      doc
        .setFont("Helvetica")
        .setTextColor("#333")
        .setFontStyle("normal")
        .setFontSize(22)
        .text(
          140,
          110,
          `support required by the carrier during the development of the activity.`,
          null,
          null,
          "center"
        );

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(20)
        .text(
          140,
          151,
          this.$store.state.settings.find(
            (x) => x.nombre == "nombre_secretario"
          ).valor,
          null,
          null,
          "center"
        );

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(85, 160, `Secretario de Agricultura y Pesca`);

      doc.addPage("l", "mm", "a4");

      doc.addImage(imgData, "PNG", 0, 0, 300, 210);
      doc.addImage(imgLogoGobernacion, "PNG", 160, 12, 110, 25);
      imgLogoFishingCard &&
        doc.addImage(imgLogoFishingCard, "PNG", 63, 12, 90, 25);

      doc.setDrawColor(0);
      doc.setFillColor("#fff");
      doc.rect(0, 42, 93, 123, "F");

      doc.addImage(imgFotoPescador, "PNG", 3, 45, 88, 117);

      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setFontSize(35)
        .setTextColor("#1A237E")
        .text(110, 55, `${(this.name || "").toUpperCase()}`);
      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setFontSize(35)
        .setTextColor("#1A237E")
        .text(110, 68, `${(this.lastName || "").toUpperCase()}`);

      doc
        .setFont("Helvetica")
        .setFontSize(30)
        .setTextColor("#1A237E")
        .setFontStyle("bold")
        .text(110, 90, `ID NUMBER`);

      doc.setFont("Helvetica").setFontSize(30).text(195, 90, `CARD No.`);

      doc
        .setFont("Helvetica")
        .setFontSize(32)
        .setTextColor(0, 12, 12)
        .setFontStyle("normal")
        .text(
          110,
          103,
          `${new Intl.NumberFormat("es-CO").format(this.identification_number)}`
        );

      doc
        .setFont("Helvetica")
        .setFontSize(32)
        .setTextColor(0, 12, 12)
        .setFontStyle("normal")
        .text(195, 103, `${this.fishin_card_number}`);
      doc
        .setFont("Helvetica")
        .setFontSize(30)
        .setTextColor("#1A237E")
        .setFontStyle("bold")
        .text(110, 123, `EXPEDITION PLACE `);
      doc
        .setFont("Helvetica")
        .setFontSize(32)
        .setTextColor(0, 12, 12)
        .setFontStyle("normal")
        .text(110, 136, `${this.fishermanExpeditionPLace}`);

      doc
        .setFont("Helvetica")
        .setFontSize(22)
        .setTextColor(0, 12, 12)
        .setFontStyle("normal")
        .text(110, 158, `ISSUED: ${this.expedition_date || ""}`);

      doc
        .setFont("Helvetica")
        .setFontSize(22)
        .setTextColor(0, 12, 12)
        .setFontStyle("normal")
        .text(195, 158, `EXPIRE: ${this.expiration_date}`);

      return doc;
    },
    async generarBase64(urlFoto) {
      this.$store.commit('LOADER', true);
      //this.$store.commit("START_LOADER");
      const { data } = await baseService.get(urlFoto, { responseType: "blob" });
      //this.$store.commit('LOADER', false);
      this.$store.commit("STOP_LOADER");
      return this.blobToBase64(data);
    },
    blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    convertDate(inputFormat) {
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
        "/"
      );
    },
    printFisherman() {
      this.$htmlToPaper("carnet");
    },
  },
};
</script>

<style lang="scss" scoped>
.clear {
  clear: both;
}
#carnet-content {
  font-family: Helvetica, Helvetica, sans-serif;
  font-size: 14px;
}
#carnet {
  width: 960px;
  margin: 0 auto;
}

.carnet-izq,
.carnet-der {
  border: 1px solid #ddd;
  border-radius: 30px;
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding: 15px 40px;
  height: 280px;
}
.carnet-dates {
  font-size: 13px;
}
table.datos-carnet {
  width: 340px;
  margin: 0 auto 5px;
}

table.datos-carnet th {
  text-align: right;
  padding-right: 10px;
  font-size: 12px;
  color: #848484;
}

table.datos-carnet td {
  text-align: left;
  padding-left: 10px;
}

.nota-legal-carnet {
  font-size: 12px;
  width: 95%;
  margin: 0 auto;
  background: #eee;
  padding: 7px;
  border-radius: 10px;
  color: #909090;
  text-align: center;
}

.datos-expedicion-carnet {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.firma-carnet-img {
  margin-left: 40px;
}

.logo-rgp-carnet,
.logo-gob-carnet {
  text-align: right;
}

.foto-carnet {
  width: 3cm;
  height: 4cm;
  background: #eee;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border: 5px solid #fff;
  float: left;
  overflow: hidden;
}

.foto-carnet img {
  text-align: center;
  width: 130px;
  height: 130px;
}

.datos-personales-carnet {
  float: left;
  padding: 30px 0 0 30px;
  width: 260px;
}

.nombre-pescador-carnet span {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
}

table.num-ids-carnet {
  text-align: left;
  width: 100%;
  border: none;
  border-spacing: 0;
  margin-top: 20px;
}

table.num-ids-carnet th {
  width: 50%;
  font-size: 12px;
  color: #848484;
}
</style>
